import React from "react";
import  "../styles/main.css";
import AppRoutes from "./AppRoutes";
const App = () => {
    return (
        <div className="container">
            <AppRoutes />
        </div>
    )
};

export default App;